import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import { mapEdgesToNodes } from "../lib/helpers"
import Container from "../components/Container/Container"
import GraphQLErrorList from "../components/graphql-error-list"
import Seo from "../components/SEO/SEO"
import PortableText from "react-portable-text"
import Helmet from "react-helmet"
import Image from "gatsby-plugin-sanity-image"
import OverlayForm from "../components/Global/OverlayForm"

import InView from "../components/InView"
// import useParallaxBlock from "../lib/parallaxBlock";

export const query = graphql`
  query NewsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      defaultImage {
        asset {
          _id
        }
      }
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...Image
            alt
            asset {
              _id
            }
          }
          title
          _rawExcerpt
          slug {
            current
          }
          categories {
            id
          }
        }
      }
    }
    categories: allSanityCategory {
      edges {
        node {
          title
          id
        }
      }
    }
    sanityNews {
      featureArticle {
        id
        mainImage {
          ...Image
          alt
          asset {
            _id
          }
        }
        slug {
          current
        }
        title
        _rawExcerpt
      }
    }
  }
`

const NewsPage = (props) => {
  const { data, errors } = props
  const [posts, setPosts] = useState([])
  const [hidePosts, setHidePosts] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [loaded, setLoaded] = useState(false)
  // const parallaxRef = useParallaxBlock(scale => 2 - scale * 2);

  const categoryNodes =
    data && data.categories && mapEdgesToNodes(data.categories)

  useEffect(() => {
    setPosts(postNodes)
  }, [])

  function filterPosts(filter) {
    setSelectedFilter(filter)
    setHidePosts(true)
    setTimeout(() => {
      if (filter) {
        setPosts(
          postNodes.filter((el) =>
            el.categories.map((el) => el.id).includes(filter)
          )
        )
      } else {
        setPosts(postNodes)
      }
      setHidePosts(false)
    }, 600)
  }

  function PostListing(props) {
    return (
      <>
        <div className="col-1">
          {props.nodes &&
            props.nodes
              .filter((n, i) => i % 2 === 0)
              .map((node) => (
                <div className="mb-10 md:pl-10 lg:mb-[78px]" key={node.id}>
                  <PostPreview {...node} />
                </div>
              ))}
        </div>
        <div className="col-2">
          {props.nodes &&
            props.nodes
              .filter((n, i) => i % 2 === 1)
              .map((node) => (
                <div className="mb-10 md:pl-10 lg:mb-[78px]" key={node.id}>
                  <PostPreview {...node} />
                </div>
              ))}
        </div>
      </>
    )
  }

  PostListing.defaultProps = {
    title: "",
    nodes: [],
  }

  function PostPreview(props) {
    return (
      <Link to={`/news/${props.slug.current}`}>
        <div className="block overflow-hidden">
          <Image
            {...props.mainImage}
            width={700}
            alt={props.mainImage.alt}
            className={
              `transition-transform duration-500 ease-in-out md:hover:transform md:hover:scale-[1.05] lazy ` +
              (loaded && " loaded")
            }
            onLoad={() => setLoaded(true)}
          />
        </div>
        <h2 className="mt-4 mb-3 font-serif text-lg">{props.title}</h2>
        {props._rawExcerpt && (
          <div className="mb-3 text-sm">
            <PortableText
              content={props._rawExcerpt}
              serializers={{
                internalLink: ({ reference, children }) => {
                  const href = `/${reference._type}/${reference.slug.current}`
                  return <Link to={href}>{children}</Link>
                },
                link: ({ href, blank, children }) => {
                  return (
                    <a
                      href={href}
                      target={blank ? "_blank" : "_self"}
                      rel={blank ? "noreferrer" : undefined}
                    >
                      {children}
                    </a>
                  )
                },
              }}
            />
          </div>
        )}
        <Link
          className="text-sm text-grey-base link"
          to={`/news/${props.slug.current}`}
        >
          Read more
        </Link>
      </Link>
    )
  }

  function DesktopCategoryListing(props) {
    return (
      <>
        {props.nodes &&
          props.nodes.map((node) => (
            <li className="my-px" key={node.id}>
              <DesktopCategory {...node} />
            </li>
          ))}
      </>
    )
  }

  DesktopCategoryListing.defaultProps = {
    title: "",
    nodes: [],
  }

  function DesktopCategory(props) {
    return (
      <button
        onClick={() => filterPosts(props.id)}
        className={selectedFilter === props.id ? "text-grey-default" : ""}
      >
        {props.title}
      </button>
    )
  }

  function MobileCategoryListing(props) {
    return (
      <>
        {props.nodes &&
          props.nodes.map((node) => (
            <option key={node.id} value={node.id}>
              {node.title}
            </option>
          ))}
      </>
    )
  }

  MobileCategoryListing.defaultProps = {
    title: "",
    nodes: [],
  }

  const FeatureProject = ({ item }) => (
    <>
      <div className="box-border border-b border-grey-default">
        <div className="container flex flex-col pb-5 md:py-0 md:grid md:grid-cols-12 md:gap-10 md:mt-10">
          <div className="md:col-span-7 md:pb-10 md:pr-10 md:border-r md:border-grey-default md:box-border">
            <Image
              {...item.mainImage}
              width={1000}
              height={680}
              alt={item.mainImage.alt}
              className={`lazy ` + (loaded && " loaded")}
              onLoad={() => setLoaded(true)}
            />
          </div>
          <div className="flex flex-col md:col-span-5 md:pb-10">
            <h3 className="mt-4 md:mt-0">Feature</h3>
            <div className="mt-3 md:mt-auto max-w-[520px]">
              <h2 className="mb-3 font-serif text-lg">{item.title}</h2>
              <Link
                className="text-sm text-grey-base link"
                to={`/news/${item.slug.current}`}
              >
                Read more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  if (errors) {
    return (
      <>
        <GraphQLErrorList errors={errors} />
      </>
    )
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)

  const site = (data || {}).site

  return (
    <>
      <Seo
        title="News"
        description={data.sanityNews.description}
        image={site.defaultImage}
      />
      <Helmet
        bodyAttributes={{
          class: "no-banner news",
        }}
      />
      <Container>
        {data.sanityNews.featureArticle.map((item, index) => (
          <FeatureProject item={item} key={index} />
        ))}

        <InView>
          <div className="relative border-b border-grey-default box-border pb-[40px] md:pb-0">
            <div className="container pb-10 mt-4 mb-6 md:pb-0 md:my-0 md:pt-10">
              <h3>News</h3>
            </div>
            <div className="container relative flex flex-wrap flex-start md:pt-[120px] md:h-full ">
              <div className="md:w-1/4 md:sticky md:h-full md:top-[81px] md:pb-[25px] md:pr-10">
                <ul className="hidden font-serif text-lg md:flex md:flex-col">
                  <li className="mb-px">
                    <button
                      onClick={() => filterPosts(null)}
                      className={
                        selectedFilter === null ? "text-grey-default" : ""
                      }
                    >
                      All
                    </button>
                  </li>
                  {categoryNodes && (
                    <DesktopCategoryListing nodes={categoryNodes} />
                  )}
                </ul>
                <select
                  className="box-border pb-2 text-lg border-b md:hidden border-grey-default"
                  name="categories"
                  value={selectedFilter === null ? "all" : selectedFilter}
                  onChange={(event) => {
                    if (event.target.value === "all") {
                      filterPosts(null)
                    } else {
                      filterPosts(event.target.value)
                    }
                  }}
                >
                  <option value="all">All news</option>
                  {categoryNodes && (
                    <MobileCategoryListing nodes={categoryNodes} />
                  )}
                </select>
              </div>
              <div className="mt-6 masonry--col-2 md:mt-0 md:w-3/4 md:pb-[120px] relative md:border-l md:border-grey-default box-border">
                <div
                  className={`masonry md:grid md:grid-cols-2 md:gap-10 ${
                    hidePosts ? "hide" : ""}`}
                >
                  {posts && (
                    <PostListing
                      nodes={posts.filter(
                        (post) =>
                          post.id !== data.sanityNews.featureArticle[0].id
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </InView>
        <OverlayForm />
      </Container>
    </>
  )
}

export default NewsPage
